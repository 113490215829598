"use client";

import Card from "src/components/NavCard";
import VideoComponent from "src/components/VideoComponent";
import React from "react";
import { PageWithLayout } from "@shesha-io/reactjs";

const Home: PageWithLayout<{}> = () => {
  return (
    <div>
    <div
      style={{
        padding: "15px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "90px",

      }}
    >
      <VideoComponent 
        videoSrc="/videos/motion-botsa.mp4"
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridColumnGap: "20px",
        }}
      >
        <Card
          title="Explore the Knowledge Base"
          url={"https://botsa-adminportal-test.shesha.dev/dynamic/boxfusion.vutomi/botsa-knowledge-base-categories/"}
          description=" Upload your company's policies and procedures, and turn Botsa into a one-stop solution for the most common employee queries."
        />
        <Card
          title="Smart Escalation"
          url={"https://botsa-adminportal-test.shesha.dev/dynamic/boxfusion.vutomi/botsa-expert-escalation-table-view"}
          description="Experience the seamless integration of AI and human expertise with Botsa's Smart Escalation feature."
        />
        <Card
          title="Advanced Analytics"
          url={
            "https://botsa-adminportal-test.shesha.dev/e"
          }
          description="Unveil the power of data-driven decision-making, track and analyse the most common queries, offering invaluable insights into employee trends and needs."
        />
      </div>
    </div>
  </div>
  );
};

export default Home;
