import React, { useEffect, useRef, useState } from 'react';
import "src/components/botsaStyles.css";

const VideoComponent = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [playCount, setPlayCount] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play();
      const handleVideoEnd = () => {
        if (playCount < 1) { // since it starts from 0, 2 means 3 plays
          setPlayCount(prevCount => prevCount + 1);
          video.play();
        } else {
          video.currentTime = 0; // Reset the video to the beginning
          video.pause(); // Pause the video at the beginning
        }
      };
      video.addEventListener('ended', handleVideoEnd);

      // Cleanup the event listener when the component unmounts
      return () => video.removeEventListener('ended', handleVideoEnd);
    }
  }, [playCount]);

  return (
    <video 
      muted
      ref={videoRef}
      className='videoContainer'
    >
      <source src={videoSrc} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoComponent;
